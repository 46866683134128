import React, {useEffect, useState} from 'react';

/*HOOKS*/
import {useMediaQuery, Device} from "../../hooks";

/*CSS*/
import styles from './Navbar.module.css'
import {Link, useLocation} from "react-router-dom";

/*IMAGES*/
import logoWhite from '../../data/img/logo/aar-logo-white.png'
import logoBlack from '../../data/img/logo/aar-logo-black.png'

/*COMPONENTS*/
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";

const Navbar = () => {
    const isMobile = useMediaQuery(Device.sm)
    const [navbar, setNavbar] = useState(false);
    const {pathname} = useLocation();
    const path = ['/', '/studio']
    /**
     * This arrow function change the background of the Navbar when you scroll down
     * 100 are pixels after that the background changes
     */
    const changeBackground = () => {
        if (window.scrollY <= 100 && (path.includes(pathname))) {

            setNavbar(false);
        } else setNavbar(true);
    }

    useEffect(() => {
        if (window.scrollY <= 100 && path.includes(pathname)) {
            setNavbar(false);
        } else {
            setNavbar(true);
        }
    }, [pathname, window.scrollY])


    window.addEventListener('scroll', changeBackground);


    return (
        <>
            <nav className={navbar ? styles.navbarActive : styles.navbar}>
                {
                    pathname !== "/studio" ? <>
                            <Link to='/'>
                                {isMobile ? <img src={navbar ? logoBlack : logoWhite} className={styles.logoMobile}
                                                 alt={"Peccato, non puoi vedere il mio bellissimo logo"}/> :
                                    <img src={navbar ? logoBlack : logoWhite} className={styles.logo}
                                         alt={"Peccato, non puoi vedere il mio bellissimo logo"}/>
                                }
                            </Link>
                            {isMobile ? <MobileMenu navbar={navbar}/> : <Menu navbar={navbar}/>}
                        </> :
                        <>
                            {navbar === true ? <>
                                {!isMobile ? <h2>
                                    UNDERBEAR STUDIO
                                </h2> : <h3 style={{marginLeft: "2rem"}}>
                                    UNDERBEAR
                                </h3>}
                                <div className={styles.alignCenter}>
                                    {!isMobile ? <div>Un progetto di </div> : null}

                                        {isMobile ?
                                            <Link to='/' style={{marginRight: "2rem", marginLeft: "0"}}>
                                            <img src={navbar ? logoBlack : logoWhite} className={styles.logoMobile}

                                                 alt={"Peccato, non puoi vedere il mio bellissimo logo"}/> </Link> :

                                            <Link to='/'>
                                                <img src={navbar ? logoBlack : logoWhite} className={styles.logoStudio}
                                                     alt={"Peccato, non puoi vedere il mio bellissimo logo"}/>
                                            </Link>
                                        }

                                </div>
                            </> : null}

                        </>

                }


            </nav>
        </>
    );
};

export default Navbar;