import React, {useState} from 'react';
import styles from './Feed.module.css'
import {CenterTitle} from "../../../index";
import Button from "../../../Button/Button";
import Instagram from "./Instagram";


const Feed = () => {
    const [show, setShow] =  useState(true);
    return (
        <>
            <section>
                {show ? <><CenterTitle children={"NEWS"}/>
                    <div className={styles.container}>
                        <Instagram
                            token={'IGQWRNZAUFBV0RROTYxZAF9PRmdRb3AyWHFQS1p5Mi1FLXlRTnNmb0JuTkh5Ry1vVlJEZAjdfMWJ2dDhYRzhOTktJdF9oSTJRMjBJelZAscFV6TWdncTFCeTZACanFrMER5U0w0ZAXROU3ByZAEJvU25YUnQxUmtkQlFOR1EZD'}
                            limit={10}
                            onChangeShow={setShow}
                        />


                        <a href={"https://www.instagram.com/aarmusiclabel/"} target={"_blank"}>
                            <Button buttonStyle={"black2"}>
                                Altre News
                            </Button>
                        </a>
                    </div></> : null}

            </section>

        </>
    );
};

export default Feed;