import React, {useContext, useEffect, useState} from 'react';
import styles from './Roster.module.css'
import {AppContext} from "../../../context/AppContext";
import {OverlayCard} from "../../index";
import {Link} from "react-router-dom";



const RosterGrid = (props) => {
    const appData = useContext(AppContext);
    const [filter, setFilter] = useState("artist");
    const [data, setData] = useState(appData.rosterList.artists);
    const filtersList = ["artist", "producer", "tutto" ];

    useEffect(() => {
        setData(appData.function.sortByAwards(appData.function.sort(appData.rosterList.artists, "name"), "awards_songs"));

    }, [])

    const {year} = props
    return (
        <>

            <div className={styles.filters}>
                {filtersList.map((x) => {
                    return (<div className={filter === x ? styles.filterActive : styles.filter} onClick={() => setFilter(x)}>
                        {x}
                    </div>)
                })}

            </div>

            <div className={styles.grid}>

                {
                    filter !== "tutto" ? data.filter(x => x.type === filter).map((x) => {
                            if(x.year.includes(year)){
                                return (
                                    <>
                                        <Link to={`/roster/${x.link}`} >
                                            <OverlayCard img={x.img[0]} text={x.name} />
                                        </Link>
                                    </>
                                )
                            }}):
                            appData.rosterList.artists.map((x, i) => {

                            if(x.year.includes(year) && x.type !== ""){
                                return (
                                    <>
                                        <Link to={`/roster/${x.link}`} >
                                            <OverlayCard img={x.img[0]} text={x.name} />
                                        </Link>
                                    </>
                                )
                            }



                })}

            </div>
        </>
    );
};

export default RosterGrid;