import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';



/*COMPONENTS*/
import { AppContext, useAppContext } from "./context/AppContext";
import {Footer, Navbar, ScrollToTop} from "./components";

/*PAGES*/
import {
    Home,
    Admin,
    Roster,
    InsertCoin,
    Artist,
    Studio,
    Faq,
    Login,
    Register,
    Contatti,
    ManageUsers,
    ManageServiceRequests
} from "./pages";
import {useEffect, useRef} from "react";



function App() {
    const sessionValidationRequired = useRef(true);

    const appData = useAppContext();


    useEffect(() => {
        if (sessionValidationRequired.current === false) return;
        appData.user.validateSession();
        sessionValidationRequired.current = false;
        // 👇️ scroll to top on page load
    }, [appData.user]);



    return (
      <>
          <AppContext.Provider value={appData}>

              <Router>
                  <ScrollToTop />
                  <Navbar/>
                  <Routes>
                      <Route path="/" element={<Home />}/>
                      <Route path="/admin" element={<Admin />}/>
                      <Route path="/admin/users" element={<ManageUsers />}/>
                      <Route path="/admin/service_requests" element={<ManageServiceRequests/>}/>
                      <Route path="/insert-coin" element={<InsertCoin />}/>
                      <Route path="/studio" element={<Studio/>}/>
                      <Route path="/roster" element={<Roster/>}/>
                      <Route path="/roster/:artistName" element={<Artist/>}/>
                      <Route path="/faq" element={<Faq/>}/>
                      <Route path="/login" element={<Login/>}/>
                      <Route path="/register" element={<Register/>}/>
                      <Route path="/contacts" element={<Contatti/>}/>
                      <Route
                          path="*"
                          element={<div>Questa pagina non esiste</div>}
                      />
                  </Routes>


                  <Footer/>
              </Router>

          </AppContext.Provider>
      </>
  );
}

export default App;
