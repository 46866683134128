import React from 'react';
import GetInsertCoin from "../../components/Pages/Admin/GetInsertCoin";

const Admin = () => {

    return (
        <div>

            <section>
                <GetInsertCoin/>
            </section>

        </div>
    );
};

export default Admin;