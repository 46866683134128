import React, {useEffect, useState} from 'react';
import styles from './Carousel.module.css'
import Carousel from "../ElasticCarousel";
import {Link} from "react-router-dom";
import useMediaQuery, {Device} from "../../hooks/useMediaQuery";
import {OverlayCard} from "../index";

const CarouselArtist = (props) => {
    const {artists, isRoster, artistImages} = props; // top artisti da vedere in home
    const isMobile = useMediaQuery(Device.sm);
    const [showArrow, setShowArrow] = useState(false)
    const breakPointsRoster = [
        {width: 1, itemsToShow: 1},
        {width: 1200, itemsToShow: 2},
        {width: 900, itemsToShow: 3},
        {width: 1200, itemsToShow: 4},
    ];

    useEffect(() => {
        if(isMobile) {
            setShowArrow(false);
        } else { setShowArrow(true) }
    }, [isMobile])


    return (
        <>
            <section>
                <div className={styles.container}>
                    <Carousel showArrows={showArrow} breakPoints={breakPointsRoster}>
                        {artists.map((x, i) => {

                            if (x.home) {
                                return (
                                    <Link key={i} to={`/roster/${x.link}`}>
                                        <div className={styles.margin}>
                                            <OverlayCard img={x.img[0]} text={x.name}/>
                                        </div>
                                    </Link>
                                )
                            }
                        })}
                    </Carousel>

                </div>
            </section>


        </>
    );
};

export default CarouselArtist;